export const seo = {
  url: 'transport-branze',
  title: {
    pl: 'Kontenery Morskie | Sprzedaż Kontenerów',
    en: 'Transport for industries| Transport in industry branches',
  },
  desc: {
    pl: `✓ Kontenery Morskie ✓ Kontenery 20'DV ✓ Kontener 40'DV ✓Sklep z kontenerami ✓Szybka realizacja`,
    en: 'Transport tailored to the specifics of a given industry. Carrying out transport with attention to detail and matching to specific products.',
  },
  keywords: ['omida', 'logistics', 'transport', 'branże', 'przemysł'],
}

export const intro = {
  title: {
    pl: 'Kontenery dla branży',
    en: 'Transport Branże',
  },
  desc: {
    pl: 'Kontenery dopasowane do specyfiki danej branży. Stawiamy na szybką realizację i dużą dostępność.',
    en: 'Transport tailored to the specifics of a given industry. Carrying out transport with attention to detail and matching to specific products.',
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  title: {
    pl: 'Kontenery dla Twojego biznesu',
    en: 'Transport dedicated to your industry',
  },
  texts: [
    {
      pl: 'Szukasz kontenerów dopasowanych do specyfiki danej gałęzi przemysłu? Nasze doświadczony zespół dostarczy w dowolne miejsce w Europie kontener dla Twojego Biznesu. Wybierając Omida Trade masz możliwość personalizacji kontenerów oraz ich składowania.',
      en: 'Are you looking for transport tailored to the specifics of a given industry branch? Our experienced team will carry out your transport with attention to detail and adaptation to your business and individual products. Choose Omida VLS - an experienced logistics partner.',
    },
  ],
}

export const buttonBack = {
  text: {
    pl: 'Powrót do transportu',
    en: 'Back to transport',
  },
  link: '/transport/',
}
