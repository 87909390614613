export const seo = {
  url: 'kontakt',
  title: {
    pl: 'Kontenery Morskie | Kontakt | Dystrybutor Kontenerów',
    en: 'Contact | Container sales and distribution',
    zh: '联系方式 | 集装箱销售与配送',
  },
  desc: {
    pl: `✓ Kontenery Morskie ✓ Kontenery 20'DV ✓ Kontener 40'DV ✓Sklep z kontenerami ✓Szybka realizacja`,
    en: `✓ Container quote ✓ 20'DV containers ✓ 40'DV containers ✓Container store ✓Fast delivery`,
    zh: `✓ 集装箱报价 ✓ 20'DV 集装箱 ✓ 40'DV 集装箱 ✓ 集装箱商店 ✓ 快速交货`,
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Kontakt',
    en: 'Contact',
    zh: '联系方式',
  },
  desc: {
    pl: `
    <span>
      <strong>OMIDA TRADE</strong></br>
      AL. GRUNWALDZKA 472C<br/>
      80-309 GDAŃSK<br/>
      <br/>
      <a href="tel:+48799061967">tel. +48 799 061 967</a></br>
      <a href="tel:+48535871468">tel. +48 535 871 468</a><br/>
      <a href="tel:+48503004174">tel. +48 503 004 174</a><br/>
      <br/>
    </span>
    `,
    en: `
    <span>
      <strong>OMIDA TRADE</strong></br>
      AL. GRUNWALDZKA 472C<br/>
      80-309 GDAŃSK<br/>
      <br/>
      Registration data:</br>
      <strong>Omida VLS Sp. z o.o.</strong></br>
      TAX: PL5851466250 <br/>
      REGON: 360603722<br/>
      NCR: 0000459017<br/>
      <br/>
    </span>
    `,
    zh: `
    <span>
      <strong>OMIDA TRADE</strong></br>
      AL. GRUNWALDZKA 472C<br/>
      80-309 GDAŃSK<br/>
      <br/>
      注册信息：</br>
      <strong>Omida VLS Sp. z o.o.</strong></br>
      税号: PL5851466250 <br/>
      REGON: 360603722<br/>
      NCR: 0000459017<br/>
      <br/>
    </span>
    `,
  },
}

export const main = {
  title: {
    pl: 'Kontakt z Omida Trade',
    en: 'Contact Omida Trade',
    zh: '联系 Omida Trade',
  },
  texts: [
    {
      pl: 'Nawiąż rozmowę z ekspertami w dziedzinie sprzedaż kontenerów. Skorzystaj z szybkiego zapytania i wyceń kontenery dla Twojego biznesu - wyświetl formularz kontaktowy poprzez przycisk po prawej stronie ekranu.',
      zh: '与集装箱销售专家展开对话。利用快速询价功能为您的企业预订集装箱——请点击屏幕右侧的按钮查看联系表单。',
    },
  ],
}
